import { SUPPORTED_PAGES_ENUM } from "~/types/breadcrumb"

export const SUPPORTED_BREAD_CRUMBS_VALUES: HomeBreadCrumbs[] = [
    {
        name: SUPPORTED_PAGES_ENUM.Home,
        breadCrumbs: [{ name: "Home" }],
    },
    {
        name: SUPPORTED_PAGES_ENUM.Store,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.store" },
            { name: "breadCrumbs.allCategories" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.AboutUs,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.aboutUs" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.ShowProduct,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.store", route: "/store" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.ShopCart,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.shopCart" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.ContactUs,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.contactUs" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.ShopDetails,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.store", route: "/store" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.Offers,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.offers", route: "/offers" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.Blog,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.blog", route: "/blog" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.FAQ,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.faq", route: "/information/frequent-asks" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.WarrantyPolicy,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.warrantyPolicy", route: "/warranty-policy" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.ShipmentPolicy,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.shipmentPolicy", route: "/shipment-policy" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.ReturnPolicy,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.returnPolicy", route: "/return-policy" },
        ],
    },
    {
        name: SUPPORTED_PAGES_ENUM.TermsConditions,
        breadCrumbs: [
            { name: "breadCrumbs.home", route: "/" },
            { name: "breadCrumbs.termsConditions", route: "/terms-conditions" },
        ],
    },
]
