<script setup lang="ts">
import LayoutContainer from "~/layouts/partials/LayoutContainer.vue"
import HeaderContainer from "~/layouts/partials/HeaderContainer.vue"
import FooterContainer from "~/layouts/partials/FooterContainer.vue"
import LayoutContent from "~/layouts/partials/LayoutContent.vue"
import MainContainer from "~/layouts/partials/MainContainer.vue"
import DrawerContainer from "~/layouts/partials/DrawerContainer.vue"
import {
    type SUPPORTED_PAGES,
    type HomeBreadCrumbItem,
} from "~/types/breadcrumb"
import { SUPPORTED_PAGES_ENUM } from "~/types/breadcrumb"
import CategoryFilterSkeleton from "~/components/Skeletons/CategoryFilterSkeleton.vue"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    title?: string
    withoutPreNav?: boolean
    withoutBanner?: boolean
    withoutBreadCrumbs?: boolean
    breadCrumbs?: HomeBreadCrumbItem[]
    currentPage?: SUPPORTED_PAGES
    lastBreadCrumbName?: string
    parentClass?: string
    withoutParentStyles?: boolean
    withoutSteps?: boolean
    customBanner?: string
    customBannerMobile?: string
    customBgBanner?: string
    bannerClass?: string
    withoutPreFooter?: boolean
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const drawer = ref()
const router = useRouter()
const route = useRoute()
const menuItemSelectedStore = useMenuItemSelectedStore()
const shopCartStore = useShopCartStore()
const initSettingsStore = useDefaultSettingsStore()
const { get: getInitSettings } = useOFetchCustom("init-settings")
const currentPageComputed = computed(() => props.currentPage)
const {
    breadCrumbs,
    addBreadCrumbsItem,
    build: buildBreadCrumbs,
} = useBreadCrumbs(currentPageComputed.value)
const currentPageStore = useCurrentPageStore()

currentPageStore.setCurrentPage(props.currentPage || SUPPORTED_PAGES_ENUM.Home)
useLazyAsyncData<void>("shop-cart-data", () => shopCartStore.fetchShopCart(), {
    server: false,
})
useLazyAsyncData<void>(
    "init-settings",
    () => {
        return new Promise((resolve, reject) => {
            getInitSettings(undefined, {
                onSuccess: (res: any) => {
                    initSettingsStore.setSettings(res.data)
                    resolve(res)
                },
                onError: (err) => reject(err),
            })
        })
    },
    {
        server: false,
    }
)

const title = computed(() => {
    const path = route.path
    if (path.includes("/store")) {
        return "Todos los productos en nuestra tienda"
    } else if (path.includes("/offers")) {
        return "Ofertas"
    } else if (path.includes("blog")) {
        return "Blog"
    }
    return props.title
})

const categoriesStore = useCategoriesStore()
const {
    categories,
    currentCategorySelected,
    loading: loadingCategories,
} = storeToRefs(categoriesStore)
const queryStore = useSearchStore()

const i18n = useI18n()
const defaultSettingsStore = useDefaultSettingsStore()
const { settings } = storeToRefs(defaultSettingsStore)

const information = computed(() => {
    return defaultSettingsStore.settings?.information
})

const menuItems = computed(() => [
    {
        route: "/store",
        name: i18n.t("home.store"),
        page: SUPPORTED_PAGES_ENUM.Store,
    },
    // {
    //     route: "/store?onlyCombo=true",
    //     name: i18n.t("home.combos"),
    //     page: SUPPORTED_PAGES_ENUM.Combos,
    // },
    {
        route: "/info",
        name: i18n.t("home.about_us"),
        page: SUPPORTED_PAGES_ENUM.AboutUs,
        isHidden: !information.value?.enable_about_us,
    },
    {
        route: "/blog",
        name: i18n.t("home.blog"),
        page: SUPPORTED_PAGES_ENUM.Blog,
    },
    {
        route: "/information/frequent-asks",
        name: i18n.t("home.faq"),
        page: SUPPORTED_PAGES_ENUM.FAQ,
        isHidden: !information.value?.enable_frequent_asks,
    },
    {
        route: "/contact-us",
        name: i18n.t("home.contact_us"),
        page: SUPPORTED_PAGES_ENUM.ContactUs,
    },
])

/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(async () => {
    categoriesStore.getCategories()
    const route_name = router.currentRoute.value.name

    if (route_name) {
        menuItemSelectedStore.setByRoute(route_name.toString())
    }
    if (props.breadCrumbs && props.breadCrumbs.length > 0) {
        breadCrumbs.value = props.breadCrumbs
        buildBreadCrumbs(breadCrumbs.value)
    }

    if (props.lastBreadCrumbName) {
        addBreadCrumbsItem(props.lastBreadCrumbName)
    }

    if (route.query.category) {
        categoriesStore.setCurrentCategorySelected(
            route.query.category as string
        )
    }
})

watch(
    () => props.lastBreadCrumbName,
    (newValue: any) => {
        if (newValue) {
            addBreadCrumbsItem(newValue)
        }
    }
)

/**
 *
 *  Functions section0
 *
 *
 */

const goToOffers = () => {
    router.push({ path: "/offers" })
}

const searchByCategory = (category: string) => {
    categoriesStore.setCurrentCategorySelected(category as string)
    router.push({
        path: "/store",
        query: { ...route.query, category: category },
    })
}
</script>

<template>
    <LayoutContainer>
        <DrawerContainer ref="drawer">
            <div class="tw-flex tw-flex-col tw-w-full tw-gap-2 tw-py-4">
                <NuxtLink to="/">
                    <ApplicationLogo
                        class="lg:tw-w-[204px] lg:tw-h-[85px] tw-mx-auto"
                    />
                </NuxtLink>
                <q-separator class="tw-h-[2px]"></q-separator>
                <ProfileMenu class="!tw-flex !tw-flex-col !tw-mx-auto" />
                <button
                    @click="goToOffers()"
                    class="tw-cursor-pointer tw-w-full tw-font-poppins tw-items-center tw-text-white tw-self-center tw-flex tw-bg-secondary hover:tw-bg-secondary-900 hover:tw-text-white tw-h-16 tw-gap-1 tw-justify-around tw-flex-wrap offer-button"
                >
                    <img
                        src="/public/img/offer.svg"
                        alt="offer"
                        class="offer-button-icon"
                    />
                    <span class="tw-font-bold tw-text-xl">{{
                        $t("home.products_in_offer")
                    }}</span>
                    <q-icon
                        name="fa-solid fa-arrow-right"
                        size="20px"
                        class="offer-button-arrow"
                    ></q-icon>
                </button>
                <MenuSubHeader
                    :menuItems="menuItems"
                    class="tw-my-2 tw-flex-col"
                />
                <DropdownCard :title="$t('categories.title')" opened>
                    <template v-if="categoriesStore.loading">
                        <CategoryFilterSkeleton :lines="5" height="5" />
                    </template>
                    <CustomTreeFilter
                        v-else
                        :options="categoriesStore.categories"
                        @onSelect="searchByCategory"
                    />
                </DropdownCard>
                <slot name="mobile-left-drawer"></slot>
            </div>
        </DrawerContainer>
        <HeaderContainer>
            <slot name="header">
                <HomeNav :toggleDrawer="() => drawer?.toggleDrawer()">
                    <template #pre-nav>
                        <HomePreNav v-if="!withoutPreNav" />
                    </template>
                    <template #mobile-left-drawer>
                        <slot name="mobileLeftDrawer"></slot>
                    </template>
                </HomeNav>
            </slot>
        </HeaderContainer>
        <LayoutContent>
            <MainContainer>
                <template #default>
                    <template v-if="!withoutBanner">
                        <slot name="banner">
                            <!-- <HomeBanner
                                :customBanner="props.customBanner"
                                :customBannerMobile="props.customBannerMobile"
                                :title="title || ''"
                                :customBgBanner="props.customBgBanner"
                                :withoutBackground="
                                    !!bannerClass && bannerClass !== ''
                                "
                                :class="bannerClass"
                            /> -->
                        </slot>
                    </template>
                    <template v-if="!withoutBreadCrumbs">
                        <HomeBreadCrumbs :title="title" :items="breadCrumbs" />
                    </template>
                    <slot name="main">
                        <div
                            :class="`${!withoutParentStyles && `${parentClass ? parentClass : ''} tw-py-20 max-container`}`"
                        >
                            <slot name="offers"></slot>
                            <slot></slot>
                        </div>
                    </slot>
                    <slot name="post_main">
                        <slot name="shop_steps">
                            <!-- <HomeShopSteps v-if="!withoutSteps" /> -->
                        </slot>
                        <slot name="most_sold"></slot>
                        <slot name="posts"></slot>
                    </slot>
                </template>
            </MainContainer>
        </LayoutContent>

        <FooterContainer>
            <div class="footer">
                <!-- <HomePreFooter v-if="!withoutPreFooter" /> -->
                <HomeFooter />
            </div>
        </FooterContainer>
    </LayoutContainer>
</template>
<style>
.offer-button:hover .offer-button-arrow {
    transform: translateX(50%);
    transition: all 0.3s;
}

.offer-button:hover .offer-button-icon {
    filter: brightness(10);
}
</style>
