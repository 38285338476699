<script setup lang="ts">
const props = defineProps<{
    lines: number
    height: string
    randomWidth?: boolean
}>()

const generateWidth = () => {
    if (!props.randomWidth) return "100%"

    return Math.floor(Math.random() * 100) + "%"
}
</script>
<template>
    <div class="tw-w-full">
        <div class="tw-flex tw-w-full tw-flex-col tw-gap-2">
            <q-skeleton
                v-for="line in lines"
                :key="line"
                :height="height"
                :width="generateWidth()"
            />
        </div>
    </div>
</template>
