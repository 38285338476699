<script setup lang="ts">
/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */
const { settings } = useDefaultSettingsStore()
/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */
</script>

<template>
    <div class="tw-w-full tw-bg-primary-900">
        <div
            class="max-container tw-h-[68px] lg:tw-h-[40px] tw-flex tw-justify-between tw-gap-2 tw-items-center"
        >
            <div class="tw-flex tw-flex-wrap tw-gap-2 lg:tw-gap-8">
                <div
                    class="tw-text-customGray-200 hover:tw-text-white tw-flex tw-gap-2 tw-items-center"
                >
                    <q-icon name="email" size="14px" />
                    <span class="tw-text-xs tw-font-medium tw-leading-[18px]">
                        <a href="mailto:info@tioDomin.com">info@tioDomin.com</a>
                    </span>
                </div>
                <div
                    class="tw-text-customGray-200 hover:tw-text-white tw-flex tw-gap-2 tw-items-center"
                >
                    <q-icon name="phone" size="14px" />
                    <span class="tw-text-xs tw-font-medium tw-leading-[18px]">
                        <a href="tel:+5359868545">+53 59868545</a>
                    </span>
                </div>
            </div>
            <div class="tw-flex tw-gap-10">
                <div class="tw-flex tw-gap-7" v-if="settings?.base_coin">
                    <CoinSelect
                        :system_coin="settings?.base_coin"
                        :coins_availables="settings?.coins_availables"
                    />
                    <!-- <LanguageSelect
                        :system_language="'ES'"
                        :languages_availables="['ES', 'EN']"
                    /> -->
                </div>
                <div
                    class="tw-h-[20px] tw-w-[1px] tw-bg-white"
                    v-if="settings?.base_coin"
                ></div>
                <Brands
                    variant="primary"
                    class="tw-text-customGray-200 hover:tw-text-white"
                />
            </div>
        </div>
    </div>
</template>

<style scoped></style>
